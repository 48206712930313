<template>
    <div>
        <b-row class="m-0">
            <b-col cols="12" class="text-left p-0">
                <b-tabs align="center">
                    <b-tab active>
                        <template #title>
                            <feather-icon icon="FilterIcon" />
                            <span>{{$t('Orders Filters')}}</span>
                        </template>

                        <div>
                            <hr>
                            <b-form @submit.prevent="submit">

                                <!-- Status -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('Status')"
                                    >
                                        <v-select
                                            v-model="form.last_status_id"
                                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                            label="name"
                                            :options="statuses"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- id -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('Order ID')"
                                    >
                                        <b-form-input 
                                            type="number"
                                            v-model="form.id"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- Mobile -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('Mobile')"
                                    >
                                        <b-form-input 
                                            type="number"
                                            v-model="form.mobile"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- Email -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('Email')"
                                    >
                                        <b-form-input 
                                            type="email"
                                            v-model="form.email"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- username -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('Shopper name')"
                                    >
                                        <b-form-input 
                                            type="text"
                                            v-model="form.username"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- city -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('City')"
                                    >
                                        <v-select
                                            v-model="form.cities_selected"
                                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                            label="name"
                                            :options="cities"
                                            multiple
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- From Date -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('From Date')"
                                    >
                                        <flat-pickr
                                            v-model="form.from_date"
                                            class="form-control"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- From Date -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('To Date')"
                                    >
                                        <flat-pickr
                                            v-model="form.to_date"
                                            class="form-control"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- payment methods -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('Payment Method')"
                                    >
                                        <v-select
                                            v-model="form.payment_method_id"
                                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                            label="name"
                                            :options="payment_methods"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- shipping methods -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('Shipping Method')"
                                    >
                                        <v-select
                                            v-model="form.shipping_method_id"
                                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                            label="name"
                                            :options="shipping_methods"
                                        />
                                    </b-form-group>
                                </b-col>
                                
                                <!-- Submit -->
                                <b-col cols="12">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mb-2"
                                    >
                                        {{$t('Filter')}}
                                    </b-button>
                                </b-col>
                                
                            </b-form>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <feather-icon icon="ZapIcon" />
                            <span>{{$t('Actions')}}</span>
                        </template>

                        <div>
                            <!-- Orders Export -->
                            <b-form @submit.prevent="submitOrdersExport">
                                <b-col cols="12">
                                    <h3 class="text-primary">{{$t('Orders Export')}}</h3>
                                </b-col>
                                <!-- From Date -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('From Date')"
                                    >
                                        <flat-pickr
                                            v-model="formOrdersExport.from_date"
                                            class="form-control"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- To Date -->
                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('To Date')"
                                    >
                                        <flat-pickr
                                            v-model="formOrdersExport.to_date"
                                            class="form-control"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- Submit Orders Export -->
                                <b-col cols="12">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mb-2"
                                    >
                                        <template v-if="!isOrderExportsLoading">
                                            {{$t('Orders Export')}}
                                        </template>
                                        <template v-if="isOrderExportsLoading">
                                            {{$t('Exporting')}}...
                                            <b-spinner small />
                                        </template>
                                    </b-button>
                                </b-col>
                            </b-form>
                            <hr>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
    directives: {
        Ripple,
    },
    components: {
        flatPickr,
    },
    data() {
        return {
            statuses: [],
            cities: [],
            payment_methods: [],
            shipping_methods: [],
            form: {
                cities_selected: []
            },
            formOrdersExport: {},

            // isLoadings
            isOrderExportsLoading: false,
        }
    },
    mounted() {
        this.getStatuses();
        this.getCities();
        this.getPaymentMethods();
        this.getShippingMethods();
    },
    methods: {
        getStatuses(){
            useJwt.get('/order-history/get-all-statuses')
            .then((response) => {
                // console.log(response);
                this.statuses = response.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
        getCities(){
            useJwt.get('/cities/get-all')
            .then((response) => {
                // console.log(response);
                this.cities = response.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
        getPaymentMethods(){
            useJwt.get('/payment-methods/get-all')
            .then((response) => {
                // console.log(response);
                this.payment_methods = response.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
        getShippingMethods(){
            useJwt.get('/shipping-methods/get-all')
            .then((response) => {
                // console.log(response);
                this.shipping_methods = response.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
        submit() {
            this.$parent.$parent.$parent.getOrders();
            this.$parent.hide();
        },
        submitOrdersExport() {
            this.isOrderExportsLoading = true

            useJwt.post('/orders-export', this.formOrdersExport , {responseType: 'arraybuffer'})
            .then((response) => {
                // console.log(response);
                // this.statuses = response.data.data
                let blob = new Blob([response.data], {
                    type: 'application/excel'
                })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'orders-'+this.formOrdersExport.from_date+'-'+this.formOrdersExport.to_date+'.xlsx'
                link.click()
                this.isOrderExportsLoading = false
            })
            .catch(response => {
                // console.log(response);
            });
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>